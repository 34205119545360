<div class="container jmservicemaintenance-redirection">
  <div class="jmservicemaintenance-redirection-img">
    <img src="../assets/images/travaux1.png" class="img-fluid" alt="">
  </div>
  <div class="jmservicemaintenance-redirection-texte">
    <p>jmservicemaintenance vous propose de réaliser
    <li>Maintenance de votre réseaux informatique</li>
    <li>Maintenance de votre pc</li>
    <li>Construction de votre pc</li></p>
  </div>
</div>
